<template>
   <div class="singles">
      <section class="breadcrumb-area">
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                <div class="inner-content clearfix">
                    <div class="title">
                       <h1>{{$t('nav.muzey-bolim2')}}</h1>
                    </div>
                    <div class="breadcrumb-menu">
                        <ul class="clearfix">
                            <li><router-link to="#">{{$t('nav.Asosiy')}}</router-link></li>
                            <li class="active">{{$t('nav.muzey-bolim2')}}</li>
                        </ul>    
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="single-pages" >
  <div class="container">
    <div class="row">
      <div class="col-md-13">
         <div class="pages">
            <h4> {{post.title[`${$i18n.locale}`]}} </h4>
            <img :src="'https://api.stateartmuseum.uz/storage/' + post.image" alt="">
            <p v-html="post.content[`${$i18n.locale}`]"></p>
              <div class="glases">
                  <span><i class="fa fa-eye"></i>{{post.views}} </span>
                  <span><i class="fa fa-calendar"></i>{{post.created_at.slice(0,10)}} </span>
              </div>
        </div> 
      </div>
    </div>
  </div>
</div>






        <!--Start footer Style2 area-->  
<!--Start footer Style2 area-->  
  <footera></footera>
  
<!--End footer Style2 area-->

   </div> 
</template>


<script>
import axios from "axios";
import footera from '../components/footera.vue'

export default {
    components: {
        'footera': footera
    },
   data() {
    return {
      post: '',
      title: '',
      content: '',
      id: this.$route.params.id
      
     
    };
  },
  async created() {
    axios
      .get("https://api.stateartmuseum.uz/api/post/" + this.id )
      .then(response => (
        this.post = response.data,
       this.title = response.data.title[`${this.$i18n.locale}`],
       this.content = response.data.title[`${this.$i18n.locale}`]
        ))
      .then(json => console.log(json))
      .catch((error) => console.log(error))
  },
 
 
}
</script>

<style lang="css">
 .singles .breadcrumb-area {
    background: linear-gradient(rgba(31, 30, 30, 0.315), rgba(12, 12, 12, 0.459)), url('https://www.regalsprings.com/wp-content/uploads/2017/07/blog_fakeNews.jpg')!important;
    background-size: cover!important;
    background-position: center;
    background-repeat: no-repeat;
}
 .singles .pages {
    width: 65%!important;
    margin: auto;
    margin-top: 3rem;
  }
  .singles .pages h4 {
      margin-bottom: 2rem;
  }
   .singles .pages img {
    max-height: 25rem!important;
    width: 90%;
    object-fit: cover;
    margin-bottom: 1.5rem;
   }

   @media (min-width: 360px) and (max-width: 740px) {
     .singles .pages {
       width: 92%!important;
     }
   }
</style>